@tailwind base;

@layer base {
  :root {
    /* Light Theme */
    --color-bg-prim-w-b: #ffffff;
    --color-bg-prim-w-110: #ffffff;
    --color-bg-prim-w-100: #ffffff;
    --color-bg-prim-w-90: #ffffff;
    --color-bg-prim-w-80: #ffffff;
    --color-bg-prim-w-70: #ffffff;
    --color-bg-prim-inv-b-w: #000000;
    --color-bg-prim-cta: #2f43f9;
    --color-bg-sec-0-110: #f5f5fa;
    --color-bg-sec-0-100: #f5f5fa;
    --color-bg-sec-0-90: #f5f5fa;
    --color-bg-sec-0-80: #f5f5fa;
    --color-bg-sec-0-70: #f5f5fa;
    --color-bg-sec-inv-100-0: #1e1e1f;
    --color-bg-sec-cta: #6977fa;
    --color-bg-tert-10-90: #e8eaf0;
    --color-bg-tert-10-80: #e8eaf0;
    --color-bg-tert-10-70: #e8eaf0;
    --color-bg-tert-20-60: #dcdde4;
    --color-bg-tert-inv-60-20: #3c3c3e;
    --color-bg-accent: #f0f1ff;
    --color-bg-blue: #f0f1ff;
    --color-bg-alert: #fff1dd;
    --color-bg-success: #e6fcee;
    --color-bg-crit: #ffe7e5;
    --color-bg-dimmer: rgba(24, 24, 28, 0.6);

    /* Border Colors */
    --color-brdr-prim-10-100: #e9eaf0;
    --color-brdr-prim-10-80: #e9eaf0;
    --color-brdr-prim-inv-80-10: #2d2d2f;
    --color-brdr-prim-hov: #dcdde4;
    --color-brdr-prim-foc: #1e1e1f;
    --color-brdr-prim-cta: #2f43f9;
    --color-brdr-sec-20-60: #dcdde4;
    --color-brdr-sec-cta: #6977fa;
    --color-brdr-blue: #2f43f9;
    --color-brdr-tert-40-50: #9899a6;
    --color-brdr-crit: #ff3c30;
    --color-brdr-quat-30-70: #b9bac6;

    /* Surface Colors */
    --color-sf-prim-cta: #2f43f9;
    --color-sf-prim-cta-hov: #3142e0;
    --color-sf-prim-cta-pres: #2b3bc6;
    --color-sf-prim-w-b: #ffffff;
    --color-sf-prim-hov-w-110: #f5f5fa;
    --color-sf-prim-pres-w-110: #e9eaf0;
    --color-sf-prim-hov-w-b: #f5f5fa;
    --color-sf-prim-pres-w-b: #e9eaf0;
    --color-sf-prim-w-80: #ffffff;
    --color-sf-prim-w-90: #ffffff;
    --color-sf-prim-hov-w-80: #f5f5fa;
    --color-sf-prim-pres-w-80: #e9eaf0;
    --color-sf-prim-w-100: #ffffff;
    --color-sf-prim-hov-w-80: #f5f5fa;
    --color-sf-prim-pres-w-80: #e9eaf0;
    --color-sf-prim-hov-w-90: #f5f5fa;
    --color-sf-prim-pres-w-90: #e9eaf0;
    --color-sf-sec-0-110: #f5f5fa;
    --color-sf-sec-hov-0-110: #e8eaf0;
    --color-sf-sec-pres-0-110: #dcdde4;
    --color-sf-sec-0-100: #f5f5fa;
    --color-sf-sec-hov-0-100: #e8eaf0;
    --color-sf-sec-pres-0-100: #dcdde4;
    --color-sf-sec-0-90: #f5f5fa;
    --color-sf-sec-hov-0-90: #e8eaf0;
    --color-sf-sec-pres-0-90: #dcdde4;
    --color-sf-act-electric-blue: #2f43f9;
    --color-sf-act-blue: #f0f1ff;
    --color-sf-act-blue-60: #f0f1ff;
    --color-sf-act-blue-70: #dcdde4;
    --color-sf-act-blue-80: #f0f1ff;
    --color-sf-crit: #ff3c30;
    --color-sf-crit-hov: #ed4c42;
    --color-sf-crit-pres: #db463d;

    /* Icon Text Colors */
    --color-icn-txt-white: #ffffff;
    --color-icn-txt-black: #000000;
    --color-icn-txt-prim: #000000;
    --color-icn-txt-prim-inv: #ffffff;
    --color-icn-txt-sec: #74757f;
    --color-icn-txt-tert: #9899a6;
    --color-icn-txt-quat: #b8bac5;
    --color-icn-txt-prim-electric: #2f43f9;
    --color-icn-txt-accent: #2f43f9;
    --color-icn-txt-blue: #2f43f9;
    --color-icn-txt-success: #00c270;
    --color-icn-txt-alert: #ff9500;
    --color-icn-txt-crit: #ff3c30;

    /* Highlight Colors */
    --color-hglt-prim: #abb3fe;
    --color-hglt-sec: #e0e3ff;
    --color-hglt-hov-prim: #ff9500;
    --color-hglt-hov-sec: #fff1dd;

    /* Logo Colors */
    --color-logo-speechify-logo: #2f43f9;
    --color-logo-speechify-logo-txt: #000000;

    --color-player-track: #ffffff;
    --color-disabled-light: #b9bac6;
  }

  :root[class~='dark'] {
    /* Dark Theme */
    --color-bg-prim-w-b: #000000;
    --color-bg-prim-w-110: #111112;
    --color-bg-prim-w-100: #1d1d1f;
    --color-bg-prim-w-90: #252527;
    --color-bg-prim-w-80: #2c2d2e;
    --color-bg-prim-w-70: #343436;
    --color-bg-prim-inv-b-w: #ffffff;
    --color-bg-prim-cta: #4759f7;
    --color-bg-sec-0-110: #111112;
    --color-bg-sec-0-100: #1d1d1f;
    --color-bg-sec-0-90: #252527;
    --color-bg-sec-0-80: #2c2d2e;
    --color-bg-sec-0-70: #343436;
    --color-bg-sec-inv-100-0: #f5f5fa;
    --color-bg-sec-cta: #5b69e5;
    --color-bg-tert-10-90: #252527;
    --color-bg-tert-10-80: #343436;
    --color-bg-tert-10-70: #3c3c3e;
    --color-bg-tert-20-60: #3c3c3e;
    --color-bg-tert-inv-60-20: #dcdde4;
    --color-bg-accent: #3c3c3e;
    --color-bg-blue: #262940;
    --color-bg-alert: #3d2c13;
    --color-bg-success: #0b2e18;
    --color-bg-crit: #3d1513;
    --color-bg-dimmer: rgba(54, 55, 58, 0.8);

    /* Border Colors */
    --color-brdr-prim-10-100: #1e1e1f;
    --color-brdr-prim-10-80: #2d2d2f;
    --color-brdr-prim-inv-80-10: #e9eaf0;
    --color-brdr-prim-hov: #343436;
    --color-brdr-prim-foc: #ffffff;
    --color-brdr-prim-cta: #5b69e5;
    --color-brdr-sec-20-60: #3c3c3e;
    --color-brdr-sec-cta: #5c6ae5;
    --color-brdr-blue: #8894fe;
    --color-brdr-tert-40-50: #9899a6;
    --color-brdr-crit: #eb3830;
    --color-brdr-quat-30-70: #343537;

    /* Surface Colors */
    --color-sf-prim-cta: #4759f7;
    --color-sf-prim-cta-hov: #4354e2;
    --color-sf-prim-cta-pres: #3c4ac4;
    --color-sf-prim-w-b: #000000;
    --color-sf-prim-hov-w-110: #1e1e1f;
    --color-sf-prim-pres-w-110: #2d2d2f;
    --color-sf-prim-hov-w-b: #111112;
    --color-sf-prim-pres-w-b: #252627;
    --color-sf-prim-w-80: #2d2d2f;
    --color-sf-prim-w-90: #252627;
    --color-sf-prim-hov-w-80: #343537;
    --color-sf-prim-pres-w-80: #3c3c3e;
    --color-sf-prim-w-100: #1e1e1f;
    --color-sf-prim-hov-w-80: #343537;
    --color-sf-prim-pres-w-80: #3c3c3e;
    --color-sf-prim-hov-w-90: #2d2d2f;
    --color-sf-prim-pres-w-90: #3c3c3e;
    --color-sf-sec-0-110: #111112;
    --color-sf-sec-hov-0-110: #1d1d1f;
    --color-sf-sec-pres-0-110: #343436;
    --color-sf-sec-0-100: #1d1d1f;
    --color-sf-sec-hov-0-100: #252527;
    --color-sf-sec-pres-0-100: #343436;
    --color-sf-sec-0-90: #252527;
    --color-sf-sec-hov-0-90: #343436;
    --color-sf-sec-pres-0-90: #3c3c3e;
    --color-sf-act-electric-blue: #8894fe;
    --color-sf-act-blue: #26283f;
    --color-sf-act-blue-60: #3c3c3e;
    --color-sf-act-blue-70: #343436;
    --color-sf-act-blue-80: #2d2d2f;
    --color-sf-crit: #eb3830;
    --color-sf-crit-hov: #d4362f;
    --color-sf-crit-pres: #bc332d;

    /* Icon Text Colors */
    --color-icn-txt-white: #ffffff;
    --color-icn-txt-black: #000000;
    --color-icn-txt-prim: #ffffff;
    --color-icn-txt-prim-inv: #000000;
    --color-icn-txt-sec: #9899a6;
    --color-icn-txt-tert: #74757f;
    --color-icn-txt-quat: #3c3c3e;
    --color-icn-txt-prim-electric: #5b69e5;
    --color-icn-txt-accent: #ffffff;
    --color-icn-txt-blue: #8894fe;
    --color-icn-txt-success: #23ae75;
    --color-icn-txt-alert: #e68600;
    --color-icn-txt-crit: #eb3830;

    /* Highlight Colors */
    --color-hglt-prim: #5665f0;
    --color-hglt-sec: #e0e3ff;
    --color-hglt-hov-prim: #e68600;
    --color-hglt-hov-sec: #fff1dd;

    /* Logo Colors */
    --color-logo-speechify-logo: #ffffff;
    --color-logo-speechify-logo-txt: #ffffff;

    --color-player-track: #343537;
    --color-disabled-light: #3c3c3e;
  }
}
