/* GreycliffCF */
@font-face {
  font-family: GreycliffCF;
  font-weight: 300;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-Light.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-weight: 400;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-Regular.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-weight: 500;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-Medium.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-weight: 600;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-DemiBold.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-weight: 700;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-Bold.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-weight: 800;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-ExtraBold.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-weight: 900;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-Heavy.otf');
}
/* GreycliffCF-Italic */
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 300;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-LightOblique.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 400;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-RegularOblique.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 500;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-MediumOblique.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 600;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-DemiBoldOblique.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 700;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-BoldOblique.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 800;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-ExtraBoldOblique.otf');
}
@font-face {
  font-family: GreycliffCF;
  font-style: italic;
  font-weight: 900;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/GreycliffCF-HeavyOblique.otf');
}
/* SFCompact */
@font-face {
  font-family: SFCompactRegular;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/SFCompactRegular.woff') format('woff');
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/SFCompactRegular.woff2') format('woff2');
}
/* Inter */
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-Light.otf');
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-Regular.otf');
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-Medium.otf');
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-SemiBold.otf');
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-Bold.otf');
}
@font-face {
  font-family: Inter;
  font-weight: 800;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-ExtraBold.otf');
}
@font-face {
  font-family: Inter;
  font-weight: 900;
  src: url('https://storage.googleapis.com/speechify-website-assets/fonts/Inter-Black.otf');
}

/* See: https://web.dev/variable-fonts/ for setting Variable Fonts properly. */
@supports not (font-variation-settings: normal) {
  /* ABCDiatype */
  @font-face {
    font-family: ABCDiatype;
    font-weight: 300;
    src: url('https://storage.googleapis.com/speechify-website-assets/fonts/ABCDiatype-Light.otf');
  }
  @font-face {
    font-family: ABCDiatype;
    font-weight: 400;
    src: url('https://storage.googleapis.com/speechify-website-assets/fonts/ABCDiatype-Regular.otf');
  }
  @font-face {
    font-family: ABCDiatype;
    font-weight: 500;
    src: url('https://storage.googleapis.com/speechify-website-assets/fonts/ABCDiatype-Medium.otf');
  }
  @font-face {
    font-family: ABCDiatype;
    font-weight: 700;
    src: url('https://storage.googleapis.com/speechify-website-assets/fonts/ABCDiatype-Bold.otf');
  }
}

@supports (font-variation-settings: normal) {
  @font-face {
    font-family: ABCDiatype;
    src:
      url('https://storage.googleapis.com/speechify-website-assets/new-speechify/fonts/ABCDiatypeVariable.woff2') format('woff2 supports variations'),
      url('https://storage.googleapis.com/speechify-website-assets/new-speechify/fonts/ABCDiatypeVariable.woff2') format('woff2-variations'),
      url('https://storage.googleapis.com/speechify-website-assets/new-speechify/fonts/ABCDiatypeVariable.woff') format('woff');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
  }
}

@font-face {
  font-family: Recoleta;
  font-weight: 600;
  src: url('https://storage.googleapis.com/speechify-website-assets/new-speechify/fonts/Recoleta-SemiBold.otf');
}
