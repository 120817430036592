.text-heading-1,
.text-heading-2,
.text-heading-3,
.text-heading-4,
.text-heading-5,
.text-heading-6,
.text-subheading-1,
.text-subheading-2,
.text-subheading-3,
.text-subheading-4,
.text-subheading-5,
.text-subheading-6,
.text-body-1,
.text-body-2,
.text-body-3,
.text-body-4,
.text-body-5,
.text-body-6,
.text-document {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
