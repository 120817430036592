img:-moz-broken {
  opacity: 0;
}

body {
  min-height: 100dvh;
  background: var(--color-bg-sec-0-100);
}

body,
#__next {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: none;
}

/* uppy */
.uppy-Dashboard-poweredBy {
  display: none !important;
}

/* onboarding */
.onboarding-card-body a {
  color: #274de1;
}

#nprogress .bar-container {
  left: 292px;
  position: fixed;
  width: calc(100% - 280px);
  height: 3px;
  top: 122px;
  overflow: hidden;
  z-index: 31;
}

#nprogress .bar {
  background-color: #6b78fc;
  position: relative;
  height: 3px;
  visibility: hidden;
  z-index: 31;
}

#nprogress .bar.active {
  visibility: visible;
}

#nprogress .peg {
  display: none;
}

#nprogress .spinner {
  display: none;
}

#nprogress .spinner-icon {
  display: none;
}

.skeleton #nprogress .bar {
  visibility: hidden;
}

.checklist-maximized {
  font-family: 'ABCDiatype';
}
.checklist-border {
  padding: 2px;
  border-radius: 0.75rem;
  background: conic-gradient(
    from 120deg,
    rgba(255, 232, 189, 1),
    rgba(255, 202, 102, 1),
    rgba(252, 115, 119, 1),
    rgba(106, 121, 253, 1),
    rgba(252, 115, 119, 1),
    rgba(255, 202, 102, 1),
    rgba(255, 232, 189, 1)
  );
}

.checklist-header {
  padding: 1rem;
  background: #e6e8ff;
  align-items: center;
}
.progress-bar {
  display: flex;
  gap: 2px;
  justify-content: space-between;
  border-radius: 0.75rem;
  height: 8px;
  width: 100%;
}
.bar-item:first-child {
  border-top-left-radius: 1.9rem;
  border-bottom-left-radius: 1.9rem;
}
.bar-item:last-child {
  border-top-right-radius: 1.9rem;
  border-bottom-right-radius: 1.9rem;
}

.bar-item {
  background-color: #fff;
  flex: 1;
}

.item-filled {
  background-color: #6a79fd;
}

.header-wrapper {
  height: 129px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  color: #364a63;
  border-radius: 0.5rem;
  border-left: 0.5rem solid #6a79fd;
  padding: 1.5rem 2rem;
  position: relative;
  box-shadow: 1px 1px 10px rgb(0, 0, 0, 0.1);
}
.header-progress__logos {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.header-progress__text h1 {
  font-size: 1.5rem;
}
.header-progress__text p {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  width: 90%;
}
.header-progress__close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.checklists div:last-child {
  margin-bottom: 0;
}
.checklist-wrapper {
  width: 340px;
  box-shadow:
    0px 16px 24px rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}
.checklist-subitem-wrapper {
  margin-left: 1.6rem;
  z-index: 1;
}
.checklist-subitem-wrapper p {
  color: #364a63;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0.75rem 0;
}
.checklist-subitem-wrapper .checklist-subitem-terms {
  color: #587393;
  margin-bottom: 0;
}
.checklist-subitem.checklist-subitem-terms a {
  text-decoration: underline;
}

.checklist-modal-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #364a63;
  font-family: 'ABCDiatype';
  padding: 32px 71px;
  text-align: center;
  margin: 0;
}
.checklist-modal-wrapper p {
  padding: 1rem;
  font-size: 0.8rem;
  margin: 0;
}

.default-header {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1.5fr 1fr;
}

.get-started {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.get-started__text {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  width: 30px;
}
.stage-buttons-wrapper {
  display: flex;
}
.stage-button {
  margin: 0 0.2rem;
  font-weight: 700;
}
.stage-button__thumb {
  background-color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #afbbc9;
}

@media (min-width: 64rem) {
  .get-started {
    flex-direction: row;
    align-items: center;
  }
  .get-started__text {
    width: 230px;
    margin-right: 48px;
    margin-bottom: 0;
  }
  .stage-button__thumb {
    width: 3.5rem;
    height: 3.5rem;
  }
  .stage-button__skeleton {
    background: #dddbdd;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    margin: 0 0.2rem;
    position: relative;
    overflow: hidden;
    animation: skeleton 1s infinite;
  }
  .stage-button__skeleton::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(124, 124, 124, 0.2959558823529411) 46%, rgba(255, 255, 255, 0) 100%);
    transform: translateX(-100%);
    animation: shimmer 2s infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.black-firday-purple-bg {
  background-image: url('https://storage.googleapis.com/speechify-website-assets/black-friday-banners-background.svg');
}

.gradient-text {
  background: linear-gradient(131deg, #0d22f3 0%, #7b00f5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* disable transparacy in tooltip */
.canvas-tooltip {
  opacity: 1 !important;
}

/* scrollbar styles for simplebar */
.simplebar-scrollbar::before {
  background-color: #8791a0;
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  opacity: 0; /* Chrome, Edge, Safari and Opera */
}
.scrollbar-hidden {
  scrollbar-color: transparent; /* Firefox */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

.no-shadow {
  box-shadow: none !important;
}

/* TODO: experiment */

/* body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
