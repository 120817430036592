:root {
  --scrollbar-width: 8px;
  --web-reader-vertical-padding: 72px;
  --web-reader-max-width: 864px;
  --html-controls-height: 6rem;
}

.classic-reader {
  font-family: Georgia;
  font-size: 18px;
  line-height: 27px;
  color: #1e1e1e;
}

.webreader {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.webreader section {
  background: transparent;
  max-width: var(--web-reader-max-width);
  min-height: 100vh;
  overflow-x: hidden;
}

.webreader.reader-api-based section {
  overflow-x: unset;
  max-width: unset;
}

.dark .classic-reader {
  color: #fff;
}

.classic-reader.reader-api-based ul > ul,
.classic-reader.reader-api-based ol > ul,
.classic-reader.reader-api-based ul > ol,
.classic-reader.reader-api-based ol > ol {
  padding-left: 1rem;
}

.classic-reader > p,
.classic-reader > h1,
.classic-reader > h2,
.classic-reader > h3,
.classic-reader > div > p,
.classic-reader > div > h1,
.classic-reader > div > h2,
.classic-reader > div > h3 {
  margin: 1rem 0;
}

.classic-reader.reader-api-based h1,
.classic-reader.reader-api-based h2,
.classic-reader.reader-api-based h3,
.classic-reader.reader-api-based h4,
.classic-reader > h1,
.classic-reader > h2,
.classic-reader > h3,
.classic-reader > div > h1,
.classic-reader > div > h2,
.classic-reader > div > h3 {
  font-family:
    Inter var,
    sans-serif;
  font-weight: 700;
  padding: 16px 0 0 0;
}

.classic-reader.reader-api-based h1,
.classic-reader > h1,
.classic-reader > div > h1 {
  font-size: 40px;
  line-height: 1.222;
}

.classic-reader.reader-api-based h2,
.classic-reader > h2,
.classic-reader > div > h2 {
  font-size: 32px;
  line-height: 1.333;
}

.classic-reader.reader-api-based h3,
.classic-reader > h3,
.classic-reader > div > h2 {
  font-size: 24px;
  line-height: 1.4;
}

.classic-reader.reader-api-based pre,
.classic-reader > pre,
.classic-reader > div > pre {
  margin: 1rem 0;
  white-space: pre-wrap;
  overflow-x: hidden;
}

.classic-reader.reader-api-based ul,
.classic-reader.reader-api-based ol,
.classic-reader > ul,
ol,
.classic-reader > div > ul,
ol {
  list-style: inside;
}

.classic-reader a {
  color: #0866f9;
}

.classic-reader.reader-api-based img {
  align-self: center;
}

.classic-reader a:hover {
  text-decoration: underline;
}

.classic-reader table {
  padding: 16px 0 16px 0;
  border: 1px solid #f1f4f9;
}

.classic-reader table th {
  font-weight: 700;
  font-size: 16px;
  background-color: #f1f4f9;
  border: 1px solid #e4eaf1;
  padding-left: 12px;
  padding-right: 12px;
  white-space: pre-wrap;
}

.classic-reader table td {
  padding: 12px;
  border-right: 1px solid #f1f4f9;
}

.dark .classic-reader a {
  color: #6aa5fd;
}

.dark .classic-reader table {
  border-color: #373737;
}

.dark .classic-reader table th {
  background-color: #111112;
  color: #fff;
  border-color: #373737;
}

.dark .classic-reader table td {
  border-color: #373737;
}

.upgrade-button-reader-end {
  text-decoration: none !important;
  color: #1c2c40 !important;
}

@media (max-width: 640px) {
  .webreader {
    max-width: 100%;
  }

  .webreader section {
    max-width: 100%;
  }

  .classic-reader.reader-api-based h1,
  .classic-reader > h1,
  .classic-reader > div > h1 {
    font-size: 26px;
  }

  .classic-reader.reader-api-based h2,
  .classic-reader > h2,
  .classic-reader > div > h1 {
    font-size: 22px;
  }

  .classic-reader.reader-api-based h3,
  .classic-reader > h3,
  .classic-reader > div > h1 {
    font-size: 20px;
  }
}

.toggle-checkbox:checked {
  right: 0;
  border-color: #6b78fc;
  background-image: none;
  background: white;
}

.toggle-checkbox:checked:hover {
  background: white;
  border-color: #6b78fc;
}

.toggle-checkbox:focus {
  outline: none;
  box-shadow: none;
  background: white;
}

.toggle-checkbox:checked:focus {
  outline: none;
  box-shadow: none;
  background: white;
  border-color: #6b78fc;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #6b78fc;
}

.toggle-checkbox:disabled {
  border-color: #cccccc;
  background-image: none;
  background: white;
}

.toggle-checkbox:disabled + .toggle-label {
  background-color: #cccccc;
}

.toggle-checkbox:disabled:hover {
  background: white;
  border-color: #cccccc;
}

.scrollbar::-webkit-scrollbar {
  width: 0;
}

.smoothScrollbar::-webkit-scrollbar {
  width: 8px;
  margin-bottom: 16px;
  background: transparent;
}

.smoothScrollbar::-webkit-scrollbar-track {
  display: none;
}

.smoothScrollbar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #8791a0;
}

.smoothScrollbar::-webkit-scrollbar-button {
  display: none;
}
