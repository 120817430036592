.slideup {
  animation: slideUp 1s cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes slideUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-8px);
  }
}
